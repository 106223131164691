// src/components/DisplayUpdates.js
import React from 'react';

function DisplayUpdates({ updates }) {
  const parseNoteText = (text) => {
    // Regular expression to match the note pattern and extract the parts
    const regex = /(\w+)\swas\schanged\sto\s(\w+)\sby:\s(\w+)/g;
    const matches = regex.exec(text);

    if (matches) {
      const [, changedValue, updatedValue, username] = matches;
      return (
        <span>
          <strong>{changedValue}</strong> was changed to <strong>{updatedValue}</strong> by: <strong>{username}</strong>
        </span>
      );
    }
    return text;
  };

  return (
    <div className="space-y-4">
      {updates.map((update, index) => (
        <div key={index} className="flex items-start space-x-4">
          <div className="flex-1">
            <div className="flex items-center space-x-2">
              <h4 className="text-sm font-medium text-gray-900">{update.username}</h4>
              <span className="text-xs text-gray-500">{new Date(update.timestamp).toLocaleString()}</span>
            </div>
            <p className="mt-2 text-sm text-gray-800">{parseNoteText(update.note_text)}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default DisplayUpdates;
