// src/components/EditInventoryItem.js
import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';

const EditInventoryItem = ({ item, onClose, onUpdate }) => {
  const [quantity, setQuantity] = useState(item.quantity);
  const [previousQuantity, setPreviousQuantity] = useState(item.quantity);
  const [history, setHistory] = useState([]);

  useEffect(() => {
    fetchHistory();
  }, []);

  const fetchHistory = async () => {
    const { data, error } = await supabase
      .from('inventory_notes')
      .select('*')
      .eq('product_id', item.id)
      .order('timestamp', { ascending: false });

    if (error) {
      console.error('Error fetching history:', error);
    } else {
      setHistory(data);
    }
  };

  const handleUpdate = async () => {
    const user = JSON.parse(localStorage.getItem('user'));

    if (!user) {
      console.error('User is not authenticated');
      return;
    }

    const now = new Date().toISOString();

    const noteText = `user: ${user.username} changed quantity from ${previousQuantity} to ${quantity}`;
    const { data: noteData, error: noteError } = await supabase
      .from('inventory_notes')
      .insert([
        {
          product_id: item.id,
          note_text: noteText,
          user_modified: user.id,
          timestamp: now, // Let PostgreSQL handle the timezone conversion
        },
      ])
      .select();  // Ensures the inserted data is returned

    if (noteError) {
      console.error('Error adding note:', noteError);
      console.log('Note Error Details:', noteError);
      return;
    }

    if (!noteData || noteData.length === 0) {
      console.error('No note data returned after insert');
      return;
    }

    const { error: updateError } = await supabase
      .from('inventory')
      .update({ quantity })
      .eq('id', item.id);

    if (updateError) {
      console.error('Error updating inventory:', updateError);
    }

    onUpdate();
    onClose();
  };

  const formatDateToNYTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', { timeZone: 'America/New_York' });
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <h2 className="text-xl font-semibold mb-4">Edit {item.product}</h2>
        <div className="mb-4">
          <p className="text-sm font-medium text-gray-700 mb-1">Current Quantity: {previousQuantity}</p>
          <label className="block text-sm font-medium text-gray-700 mb-1">New Quantity</label>
          <input
            type="number"
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            value={quantity}
            onChange={(e) => setQuantity(Number(e.target.value))}
          />
        </div>
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Change History</h3>
          <div className="max-h-48 overflow-y-auto border border-gray-300 rounded-md p-2">
            <ul className="space-y-2">
              {history.length > 0 ? (
                history.map((entry) => (
                  <li key={entry.id} className="text-sm text-gray-600">
                    {formatDateToNYTime(entry.timestamp)}: {entry.note_text}
                  </li>
                ))
              ) : (
                <li className="text-sm text-gray-600">No change history found.</li>
              )}
            </ul>
          </div>
        </div>
        <div className="flex justify-end space-x-4">
          <button className="px-4 py-2 bg-gray-300 rounded-md" onClick={onClose}>Cancel</button>
          <button className="px-4 py-2 bg-indigo-600 text-white rounded-md" onClick={handleUpdate}>Save</button>
        </div>
      </div>
    </div>
  );
};

export default EditInventoryItem;
