// src/components/BarChart.js
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { supabase } from '../supabaseClient';

const BarChart = () => {
  const [chartData, setChartData] = useState({ cities: [], counts: [] });

  useEffect(() => {
    fetchChartData();
  }, []);

  const fetchChartData = async () => {
    try {
      const { data, error } = await supabase
        .from('customers')
        .select('city');

      if (error) {
        throw error;
      }

      const cityCounts = data.reduce((acc, customer) => {
        acc[customer.city] = (acc[customer.city] || 0) + 1;
        return acc;
      }, {});

      const sortedCities = Object.entries(cityCounts)
        .sort(([, a], [, b]) => b - a)
        .slice(0, 7);

      const cities = sortedCities.map(([city]) => city);
      const counts = sortedCities.map(([, count]) => count);

      setChartData({ cities, counts });
    } catch (error) {
      console.error('Error fetching chart data:', error);
    }
  };

  const chartConfig = {
    series: [
      {
        name: "Customers",
        data: chartData.counts,
      },
    ],
    chart: {
      type: "bar",
      height: 240,
      toolbar: {
        show: false,
      },
    },
    title: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#020617"],
    plotOptions: {
      bar: {
        columnWidth: "40%",
        borderRadius: 2,
      },
    },
    xaxis: {
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      labels: {
        style: {
          colors: "#616161",
          fontSize: "12px",
          fontFamily: "inherit",
          fontWeight: 400,
        },
      },
      categories: chartData.cities,
    },
    yaxis: {
      labels: {
        style: {
          colors: "#616161",
          fontSize: "12px",
          fontFamily: "inherit",
          fontWeight: 400,
        },
        stepSize: 5,
      },
    },
    grid: {
      show: true,
      borderColor: "#dddddd",
      strokeDashArray: 5,
      xaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: 5,
        right: 20,
      },
    },
    fill: {
      opacity: 0.8,
    },
    tooltip: {
      theme: "dark",
    },
  };

  return (
    <Chart options={chartConfig} series={chartConfig.series} type="bar" height={240} />
  );
};

export default BarChart;
