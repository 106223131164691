// src/components/CustomerInfo.js
import React, { useState } from 'react';
import { supabase } from '../supabaseClient';

function CustomerInfo({ customerData, isEditing, handleEditChange, toggleEdit, handleSaveSuccess }) {
  const [loading, setLoading] = useState(false);
  const [originalData] = useState({ ...customerData }); // Store the original data

  const handleSave = async () => {
    if (!customerData || !customerData.id) {
      console.error('Invalid customer data:', customerData);
      return;
    }

    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('customers')
        .update({
          name: customerData.name,
          email: customerData.email,
          phone: customerData.phone,
          address: customerData.address,
          city: customerData.city,
          state: customerData.state,
          zip_code: customerData.zip_code,
        })
        .eq('id', customerData.id);

      if (error) {
        throw error;
      }

      // Detect changes and create a note
      const changes = [];
      const user = JSON.parse(localStorage.getItem('user'));
      for (const key in originalData) {
        if (originalData[key] !== customerData[key]) {
          changes.push(`${key} was changed to ${customerData[key]} by: ${user.username}`);
        }
      }

      if (changes.length > 0) {
        const noteText = changes.join('. ');

        const { data: noteData, error: noteError } = await supabase
          .from('notes')
          .insert([
            {
              customer_id: customerData.id,
              note_text: noteText,
              user_id: user.id,
              timestamp: new Date().toISOString(),
            }
          ]);

        if (noteError) {
          throw noteError;
        }
        // Add the new note to the updates section immediately
        handleSaveSuccess(noteData[0]);
      }

      toggleEdit(); // Switch back to view mode
    } catch (error) {
      console.error('Error updating customer:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg min-h-[400px] border border-gray-200">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Customer Profile</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details.</p>
      </div>
      <div className="border-t border-gray-200">
        <dl>
          {[
            { label: 'Name', value: 'name' },
            { label: 'Email address', value: 'email' },
            { label: 'Phone number', value: 'phone' },
            { label: 'Address', value: 'address' },
            { label: 'City', value: 'city' },
            { label: 'State', value: 'state' },
            { label: 'Zip Code', value: 'zip_code' },
          ].map((field, index) => (
            <div key={field.value} className={`${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 border-t border-gray-200`}>
              <dt className="text-sm font-medium text-gray-500">{field.label}</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {isEditing ? (
                  <input
                    type="text"
                    name={field.value}
                    value={customerData[field.value] || ''}
                    onChange={handleEditChange}
                    className="mt-1 block w-full px-0 py-0 border-none text-sm text-gray-900 focus:outline-none focus:ring-0 sm:text-sm"
                  />
                ) : (
                  <span className="font-semibold">{customerData[field.value]}</span>
                )}
              </dd>
            </div>
          ))}
        </dl>
      </div>
      {isEditing && (
        <div className="mt-4 flex justify-center space-x-2">
          <button onClick={handleSave} className="px-4 py-2 bg-indigo-600 text-white rounded-md text-sm" disabled={loading}>
            {loading ? 'Saving...' : 'Save'}
          </button>
          <button onClick={toggleEdit} className="px-4 py-2 bg-gray-300 text-black rounded-md text-sm">
            Cancel
          </button>
        </div>
      )}
    </div>
  );
}

export default CustomerInfo;
