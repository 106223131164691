// src/components/Staff.js
import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import UserDetails from './UserDetails';

const Staff = () => {
  const [staff, setStaff] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showAddStaffModal, setShowAddStaffModal] = useState(false);
  const [newStaff, setNewStaff] = useState({ username: '', password: '', role: 'employee' });

  useEffect(() => {
    fetchStaff();
  }, []);

  const fetchStaff = async () => {
    try {
      const { data, error } = await supabase
        .from('users')
        .select('*');

      if (error) {
        throw error;
      }

      setStaff(data);
    } catch (error) {
      console.error('Error fetching staff:', error);
    }
  };

  const handleAddStaffClick = () => {
    setShowAddStaffModal(true);
  };

  const handleCloseAddStaff = () => {
    setShowAddStaffModal(false);
    setNewStaff({ username: '', password: '', role: 'employee' });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewStaff((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddStaffSubmit = async (e) => {
    e.preventDefault();
    try {
      const { error } = await supabase
        .from('users')
        .insert([{ 
          username: newStaff.username, 
          password: newStaff.password, 
          role: newStaff.role 
        }]);

      if (error) {
        throw error;
      }

      fetchStaff();
      handleCloseAddStaff();
    } catch (error) {
      console.error('Error adding staff member:', error);
    }
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
  };

  const handleCloseUserDetails = () => {
    setSelectedUser(null);
  };

  const handleUpdateUser = (updatedUser, username = null) => {
    if (updatedUser) {
      setStaff((prevStaff) =>
        prevStaff.map((user) => (user.username === updatedUser.username ? updatedUser : user))
      );
    } else if (username) {
      setStaff((prevStaff) => prevStaff.filter((user) => user.username !== username));
    }
  };

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8">
      <div className="py-8">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-lg font-semibold">Staff Management</h1>
          <button onClick={handleAddStaffClick} className="px-4 py-2 bg-indigo-600 text-white rounded-md text-sm">Add Staff Member</button>
        </div>
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 border border-gray-400 rounded-md">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Username</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Last Login</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {staff.length > 0 ? (
                  staff.map((user, index) => (
                    <tr key={index} onClick={() => handleUserClick(user)} className="cursor-pointer">
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{user.username}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{user.role}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{user.last_login ? new Date(user.last_login).toLocaleString() : 'Never'}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-center">No staff members found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {showAddStaffModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">Add New Staff Member</h3>
                <div className="mt-2">
                  <form onSubmit={handleAddStaffSubmit}>
                    <div className="mb-4">
                      <label htmlFor="username" className="block text-sm font-medium text-gray-700">Username</label>
                      <input
                        type="text"
                        name="username"
                        id="username"
                        value={newStaff.username}
                        onChange={handleInputChange}
                        className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-2 border-gray-400 rounded-md"
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        value={newStaff.password}
                        onChange={handleInputChange}
                        className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-2 border-gray-400 rounded-md"
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="role" className="block text-sm font-medium text-gray-700">Role</label>
                      <select
                        name="role"
                        id="role"
                        value={newStaff.role}
                        onChange={handleInputChange}
                        className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-2 border-gray-400 rounded-md"
                        required
                      >
                        <option value="employee">Employee</option>
                        <option value="manager">Manager</option>
                      </select>
                    </div>
                    <div className="mt-5 sm:mt-6">
                      <button
                        type="submit"
                        className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                      >
                        Add Staff Member
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                  onClick={handleCloseAddStaff}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {selectedUser && (
        <UserDetails
          user={selectedUser}
          onClose={handleCloseUserDetails}
          onUpdate={handleUpdateUser}
        />
      )}
    </div>
  );
};

export default Staff;
