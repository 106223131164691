// src/components/Account.js
import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import { FaTimes } from 'react-icons/fa';
import Alert from './Alert';

const Account = () => {
  const [user, setUser] = useState(null);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const fetchUserDetails = async () => {
    try {
      const storedUser = JSON.parse(localStorage.getItem('user'));
      if (storedUser) {
        const { data, error } = await supabase
          .from('users')
          .select('*')
          .eq('username', storedUser.username)
          .single();

        if (error) {
          throw error;
        }

        setUser(data);
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      setPasswordError('New passwords do not match.');
      return;
    }

    try {
      const { data: userData, error: fetchError } = await supabase
        .from('users')
        .select('*')
        .eq('username', user.username)
        .eq('password', currentPassword)
        .single();

      if (fetchError || !userData) {
        setPasswordError('Current password is incorrect.');
        return;
      }

      const { error: updateError } = await supabase
        .from('users')
        .update({ password: newPassword })
        .eq('id', user.id);

      if (updateError) {
        throw updateError;
      }

      setCurrentPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
      setPasswordError('');
      setShowPasswordModal(false);
      setShowSuccessAlert(true);
    } catch (error) {
      console.error('Error updating password:', error);
    }
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg p-4 border border-gray-400">
      {showSuccessAlert && (
        <Alert message="Successfully updated password" onClose={() => setShowSuccessAlert(false)} />
      )}
      <div className="px-4 py-5 sm:px-6 border-b border-gray-400">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Account Information</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Details about your account.</p>
      </div>
      <div className="border-t border-gray-400">
        <dl>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 border-b border-gray-400">
            <dt className="text-sm font-medium text-gray-500">Username</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{user.username}</dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 border-b border-gray-400">
            <dt className="text-sm font-medium text-gray-500">Role</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{user.role}</dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 border-b border-gray-400">
            <dt className="text-sm font-medium text-gray-500">Actions</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <button 
                className="text-indigo-600 hover:text-indigo-900"
                onClick={() => setShowPasswordModal(true)}
              >
                Change Password
              </button>
            </dd>
          </div>
        </dl>
      </div>

      {showPasswordModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <button onClick={() => setShowPasswordModal(false)} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
                  <FaTimes />
                </button>
                <h3 className="text-lg leading-6 font-medium text-gray-900">Change Password</h3>
                <div className="mt-2">
                  <form onSubmit={handlePasswordChange}>
                    <div className="mb-4">
                      <label htmlFor="currentPassword" className="block text-sm font-medium text-gray-700">Current Password</label>
                      <input
                        type="password"
                        name="currentPassword"
                        id="currentPassword"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-2 border-gray-400 rounded-md"
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">New Password</label>
                      <input
                        type="password"
                        name="newPassword"
                        id="newPassword"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-2 border-gray-400 rounded-md"
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="confirmNewPassword" className="block text-sm font-medium text-gray-700">Confirm New Password</label>
                      <input
                        type="password"
                        name="confirmNewPassword"
                        id="confirmNewPassword"
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                        className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-2 border-gray-400 rounded-md"
                        required
                      />
                    </div>
                    {passwordError && (
                      <div className="mb-4 text-sm text-red-600">
                        {passwordError}
                      </div>
                    )}
                    <div className="flex justify-end">
                      <button
                        type="submit"
                        className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                      >
                        Change Password
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Account;
