// src/components/Header.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FiUser } from 'react-icons/fi';
import { supabase } from '../supabaseClient';

const Header = ({ onLogout }) => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) {
        throw error;
      }
      onLogout();
    } catch (error) {
      console.error('Error logging out:', error.message);
    }
  };

  return (
    <header className="flex items-center justify-end px-4 py-2">
      <nav className="flex items-center">
        <button onClick={() => navigate('/account')} className="ml-4">
          <FiUser className="w-6 h-6 text-gray-700" />
        </button>
        <button onClick={handleLogout} className="ml-4 text-gray-700">
          Logout
        </button>
      </nav>
    </header>
  );
};

export default Header;
