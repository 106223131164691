// src/components/Financials.js
import React from 'react';

const Financials = () => {
  return (
    <div className="p-4">
      <h1 className="text-lg font-semibold">Financials</h1>
      <div className="mt-4">
        <p>Financial details will be displayed here.</p>
      </div>
    </div>
  );
};

export default Financials;
