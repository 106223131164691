// src/components/Alert.js
import React from 'react';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';

const Alert = ({ message, onClose }) => {
  return (
    <div className="flex items-center bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
      <FaCheckCircle className="fill-current w-5 h-5 mr-2" />
      <span className="flex-grow">{message}</span>
      <button onClick={onClose} className="text-green-700 hover:text-green-900">
        <FaTimes />
      </button>
    </div>
  );
};

export default Alert;
