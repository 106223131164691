// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Customers from './components/Customers';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import Account from './components/Account';
import Staff from './components/Staff';  
import Calendar from './components/Calendar'; 
import Inventory from './components/Inventory'; 
import Financials from './components/Financials'; 
import './App.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setIsAuthenticated(true);
      setUserRole(user.role);
    } else {
      setIsAuthenticated(false);
      setUserRole('');
    }
  }, []);

  const handleLogin = (status, role) => {
    setIsAuthenticated(status);
    setUserRole(role);
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    setIsAuthenticated(false);
    setUserRole('');
  };

  return (
    <Router>
      {isAuthenticated ? (
        <div className="App">
          <Sidebar userRole={userRole} /> 
          <Header onLogout={handleLogout} />
          <div className="main-content">
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/customers" element={<Customers />} />
              <Route path="/account" element={<Account />} />
              {userRole === 'manager' && <Route path="/staff" element={<Staff />} />} 
              {userRole === 'manager' && <Route path="/financials" element={<Financials />} />} 
              <Route path="/calendar" element={<Calendar />} /> 
              <Route path="/inventory" element={<Inventory />} /> 
              <Route path="*" element={<Navigate replace to="/dashboard" />} />
            </Routes>
          </div>
        </div>
      ) : (
        <div className="auth-container">
          <Routes>
            <Route path="/login" element={<Login onLogin={handleLogin} />} />
            <Route path="*" element={<Navigate replace to="/login" />} />
          </Routes>
        </div>
      )}
    </Router>
  );
}

export default App;
