// src/components/Customers.js
import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import Pagination from './Pagination';
import CustomerDetails from './CustomerDetails';
import AddCustomer from './AddCustomer';
import { formatPhoneNumber } from '../utils/formatPhoneNumber'; // Import the utility function

function Customers() {
  const [customers, setCustomers] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchName, setSearchName] = useState('');
  const [searchCity, setSearchCity] = useState('');
  const [searchState, setSearchState] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showAddCustomer, setShowAddCustomer] = useState(false);

  useEffect(() => {
    fetchCustomers();
  }, [page, searchName, searchCity, searchState]);

  const fetchCustomers = async () => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));

      if (!user) {
        console.error('User is not authenticated');
        return;
      }

      let query = supabase
        .from('customers')
        .select('*', { count: 'exact' })
        .range((page - 1) * 10, page * 10 - 1);

      if (searchName) {
        query = query.ilike('name', `%${searchName}%`);
      }
      if (searchCity) {
        query = query.ilike('city', `%${searchCity}%`);
      }
      if (searchState) {
        query = query.ilike('state', `%${searchState}%`);
      }

      const { data, count, error } = await query;

      if (error) {
        console.error('Error fetching customers:', error);
        setCustomers([]);
      } else {
        console.log('Fetched customers:', data); // Debugging: Log fetched data
        setCustomers(data);
        setTotalPages(Math.ceil(count / 10));
      }
    } catch (error) {
      console.error('Error fetching customers:', error);
      setCustomers([]);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'searchName') setSearchName(value);
    if (name === 'searchCity') setSearchCity(value);
    if (name === 'searchState') setSearchState(value);
  };

  const handleCustomerClick = (customer) => {
    setSelectedCustomer(customer);
  };

  const handleCloseDetails = () => {
    setSelectedCustomer(null);
  };

  const handleAddCustomerClick = () => {
    setShowAddCustomer(true);
  };

  const handleCloseAddCustomer = () => {
    setShowAddCustomer(false);
  };

  const handleAddCustomerSuccess = () => {
    fetchCustomers();
  };

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8">
      <div className="py-8">
        <div className="flex justify-between items-center mb-4">
          <div className="flex space-x-4">
            <input
              type="text"
              name="searchName"
              value={searchName}
              onChange={handleInputChange}
              placeholder="Search by name"
              className="px-4 py-2 border rounded-md text-sm"
            />
            <input
              type="text"
              name="searchCity"
              value={searchCity}
              onChange={handleInputChange}
              placeholder="Search by city"
              className="px-4 py-2 border rounded-md text-sm"
            />
            <input
              type="text"
              name="searchState"
              value={searchState}
              onChange={handleInputChange}
              placeholder="Search by state"
              className="px-4 py-2 border rounded-md text-sm"
            />
          </div>
          <button onClick={handleAddCustomerClick} className="px-4 py-2 bg-indigo-600 text-white rounded-md text-sm">Add Customer</button>
        </div>
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 border border-gray-200 rounded-md">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Phone</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Address</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">City</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">State</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Zip Code</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {Array.isArray(customers) && customers.length > 0 ? (
                  customers.map((customer) => (
                    <tr 
                      key={customer.id}  // Ensure 'customer.id' is unique
                      onClick={() => handleCustomerClick(customer)} 
                      className="cursor-pointer"
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{customer.name}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{customer.email}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{formatPhoneNumber(customer.phone)}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 flex items-center">
                        <span className={`inline-block w-2 h-2 mr-2 rounded-full ${customer.status === 'Active' ? 'bg-green-500' : 'bg-red-500'}`}></span>
                        {customer.status}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{customer.address}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{customer.city}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{customer.state}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{customer.zip_code}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-center">No customers found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <Pagination
          currentPage={page}
          totalPages={totalPages}
          onPageChange={setPage}
        />
      </div>

      {selectedCustomer && (
        <CustomerDetails 
          customer={selectedCustomer} 
          onClose={handleCloseDetails} 
        />
      )}

      {showAddCustomer && (
        <AddCustomer 
          onClose={handleCloseAddCustomer}
          onAddCustomerSuccess={handleAddCustomerSuccess}
        />
      )}
    </div>
  );
}

export default Customers;
