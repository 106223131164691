// src/components/CustomerDetails.js
import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { supabase } from '../supabaseClient';
import CustomerInfo from './CustomerInfo';
import DisplayUpdates from './DisplayUpdates';

function CustomerDetails({ customer, onClose }) {
  const [isEditing, setIsEditing] = useState(false);
  const [customerData, setCustomerData] = useState({ ...customer });
  const [newUpdate, setNewUpdate] = useState('');
  const [updateList, setUpdateList] = useState([]);
  const [currentUser, setCurrentUser] = useState('');

  useEffect(() => {
    const fetchUpdates = async () => {
      try {
        const { data: updates, error } = await supabase
          .from('notes')
          .select('*')
          .eq('customer_id', customer.id)
          .order('timestamp', { ascending: false });

        if (error) {
          throw error;
        }

        // Fetch usernames for the updates
        const userData = await supabase.from('users').select('id, username');
        const userMap = userData.data.reduce((acc, user) => {
          acc[user.id] = user.username;
          return acc;
        }, {});

        const updatesWithUsername = updates.map(update => ({
          ...update,
          username: userMap[update.user_id] || 'Unknown'
        }));

        setUpdateList(updatesWithUsername);
      } catch (error) {
        console.error('Error fetching updates:', error);
      }
    };

    const fetchCurrentUser = () => {
      const user = JSON.parse(localStorage.getItem('user'));

      if (user) {
        setCurrentUser(user);
      } else {
        console.error('User is not authenticated');
      }
    };

    fetchUpdates();
    fetchCurrentUser();
  }, [customer.id]);

  const handleUpdateChange = (e) => {
    setNewUpdate(e.target.value);
  };

  const handleAddUpdate = async () => {
    if (!newUpdate.trim()) return;

    try {
      const { data, error } = await supabase
        .from('notes')
        .insert([
          {
            customer_id: customer.id,
            note_text: newUpdate,
            user_id: currentUser.id,
            timestamp: new Date().toISOString(),
          }
        ]);

      if (error) {
        throw error;
      }

      setUpdateList([
        {
          note_text: newUpdate,
          timestamp: new Date().toISOString(),
          user_id: currentUser.id,
          username: currentUser.username
        },
        ...updateList
      ]);
      setNewUpdate('');
    } catch (error) {
      console.error('Error adding update:', error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleAddUpdate();
    }
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setCustomerData({ ...customerData, [name]: value });
  };

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleSaveSuccess = (newNote) => {
    setCustomerData({ ...customerData });
    setUpdateList([
      {
        note_text: newNote.note_text,
        timestamp: newNote.timestamp,
        user_id: newNote.user_id,
        username: currentUser.username
      },
      ...updateList
    ]);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div className="bg-white p-6 rounded-lg shadow-lg w-2/3 relative">
        <button onClick={onClose} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
          <FaTimes />
        </button>
        <div className="flex">
          <div className="w-1/2 flex flex-col justify-between">
            <CustomerInfo 
              customerData={customerData} 
              isEditing={isEditing} 
              handleEditChange={handleEditChange} 
              toggleEdit={toggleEdit}
              handleSaveSuccess={handleSaveSuccess}
            />
            <div className="flex justify-center mt-4">
              {!isEditing && (
                <button onClick={toggleEdit} className="px-4 py-2 bg-indigo-600 text-white rounded-md text-sm flex items-center">
                  Edit
                </button>
              )}
            </div>
          </div>
          <div className="w-1/2 pl-4 flex flex-col justify-between">
            <div className="flex-1 overflow-y-auto mb-4 pr-2" style={{ maxHeight: '300px' }}>
              <h2 className="text-lg font-semibold mb-4">Updates</h2>
              <DisplayUpdates updates={updateList} />
            </div>
            <div className="mt-4 border-t border-gray-200 pt-4">
              <textarea
                value={newUpdate}
                onChange={handleUpdateChange}
                onKeyPress={handleKeyPress}
                className="w-full p-2 border rounded-md mb-2"
                rows="2"
                placeholder="Add your update..."
              ></textarea>
              <div className="flex justify-between items-center">
                <div className="flex space-x-2 text-gray-400">
                  <button><span role="img" aria-label="Attach"><i className="fas fa-paperclip"></i></span></button>
                  <button><span role="img" aria-label="Emoji"><i className="fas fa-smile"></i></span></button>
                </div>
                <button onClick={handleAddUpdate} className="px-4 py-2 bg-indigo-600 text-white rounded-md text-sm">Post</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerDetails;
