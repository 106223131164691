// src/components/Inventory.js
import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import EditInventoryItem from './EditInventoryItem';

// Utility to dynamically import images
const importAllImages = (requireContext) => {
  const images = {};
  requireContext.keys().forEach((key) => {
    const imageName = key.replace('./', '').replace('.png', '');
    images[imageName] = requireContext(key);
  });
  return images;
};

// Import all images from the images directory
const images = importAllImages(require.context('./images', false, /\.png$/));

const Inventory = () => {
  const [inventory, setInventory] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    fetchInventory();
  }, []);

  const fetchInventory = async () => {
    const { data, error } = await supabase
      .from('inventory')
      .select(`
        id,
        product,
        quantity
      `)
      .order('id', { ascending: true });

    if (error) {
      console.error('Error fetching inventory:', error);
    } else {
      const updatedData = await Promise.all(data.map(async (item) => {
        const { data: latestNote } = await supabase
          .from('inventory_notes')
          .select('user_modified, timestamp')
          .eq('product_id', item.id)
          .order('timestamp', { ascending: false })
          .limit(1);

        if (latestNote.length > 0) {
          const userId = latestNote[0].user_modified;
          const { data: user } = await supabase
            .from('users')
            .select('username')
            .eq('id', userId)
            .single();

          return {
            ...item,
            last_time_updated: latestNote[0].timestamp,
            user_modified: user ? user.username : 'Unknown',
          };
        }

        return {
          ...item,
          last_time_updated: null,
          user_modified: 'Unknown',
        };
      }));
      setInventory(updatedData);
    }
  };

  const getImagePath = (productName) => {
    return images[productName] || null;
  };

  const handleEditClick = (item) => {
    setSelectedItem(item);
  };

  const handleCloseEdit = () => {
    setSelectedItem(null);
    fetchInventory();
  };

  const formatDateToNYTime = (timestamp) => {
    if (!timestamp) return 'N/A';
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', { timeZone: 'America/New_York' });
  };

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-10 py-8">
      <h1 className="text-lg font-semibold mb-4">Inventory</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {inventory.length > 0 ? (
          inventory.map((item) => (
            <div key={item.id} className="bg-white shadow-md border border-gray-200 rounded-lg p-6">
              {getImagePath(item.product) ? (
                <img
                  src={getImagePath(item.product)}
                  alt={item.product}
                  className="w-full h-64 object-cover rounded-md mb-4"
                />
              ) : (
                <div className="w-full h-64 flex items-center justify-center bg-gray-200 rounded-md mb-4">
                  <span className="text-gray-500">No Image</span>
                </div>
              )}
              <div className="mb-4">
                <h2 className="text-xl font-semibold text-gray-800">{item.product}</h2>
                <p className="text-base text-gray-600">Quantity: {item.quantity}</p>
                <p className="text-base text-gray-600">Last Updated: {formatDateToNYTime(item.last_time_updated)}</p>
                <p className="text-base text-gray-600">Modified By: {item.user_modified}</p>
              </div>
              <button className="w-full bg-indigo-600 text-white py-2 px-4 rounded-md mt-4" onClick={() => handleEditClick(item)}>Edit</button>
            </div>
          ))
        ) : (
          <p className="col-span-full text-center text-base text-gray-600">No inventory found.</p>
        )}
      </div>
      {selectedItem && (
        <EditInventoryItem
          item={selectedItem}
          onClose={handleCloseEdit}
          onUpdate={fetchInventory}
        />
      )}
    </div>
  );
};

export default Inventory;
