// src/components/AddCustomer.js
import React, { useState } from 'react';
import { supabase } from '../supabaseClient';
import { FaTimes } from 'react-icons/fa';

function AddCustomer({ onClose, onAddCustomerSuccess }) {
  const [customerData, setCustomerData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zip_code: ''
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const validate = () => {
    const errors = {};
    if (!/^\d{10}$/.test(customerData.phone)) {
      errors.phone = 'Phone number must be 10 digits';
    }
    if (!/^[A-Z]{2}$/.test(customerData.state)) {
      errors.state = 'State code must be 2 uppercase letters';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerData({ ...customerData, [name]: value });
  };

  const handleSave = async () => {
    if (!validate()) return;

    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('customers')
        .insert([
          {
            name: customerData.name,
            email: customerData.email,
            phone: customerData.phone,
            address: customerData.address,
            city: customerData.city,
            state: customerData.state,
            zip_code: customerData.zip_code
          }
        ]);

      if (error) {
        throw error;
      }

      onAddCustomerSuccess();
      onClose();
    } catch (error) {
      console.error('Error adding customer:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div className="bg-white p-6 rounded-lg shadow-lg w-2/3 relative">
        <button onClick={onClose} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
          <FaTimes />
        </button>
        <h2 className="text-lg font-semibold mb-4">Add New Customer</h2>
        <div className="space-y-4">
          {[
            { label: 'Name', value: 'name' },
            { label: 'Email address', value: 'email' },
            { label: 'Phone number', value: 'phone' },
            { label: 'Address', value: 'address' },
            { label: 'City', value: 'city' },
            { label: 'State', value: 'state' },
            { label: 'Zip Code', value: 'zip_code' },
          ].map((field) => (
            <div key={field.value} className="flex flex-col">
              <label className="text-sm font-medium text-gray-700">{field.label}</label>
              <input
                type="text"
                name={field.value}
                value={customerData[field.value]}
                onChange={handleInputChange}
                className="mt-1 block w-full px-4 py-2 border rounded-md text-sm"
              />
              {errors[field.value] && <p className="text-sm text-red-600">{errors[field.value]}</p>}
            </div>
          ))}
        </div>
        <div className="mt-6 flex justify-end">
          <button onClick={handleSave} className="px-4 py-2 bg-indigo-600 text-white rounded-md text-sm" disabled={loading}>
            {loading ? 'Saving...' : 'Save'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddCustomer;
