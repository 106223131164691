// src/components/Dashboard.js
import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import BarChart from './BarChart';

const Dashboard = () => {
  const [totalCustomers, setTotalCustomers] = useState(0);

  useEffect(() => {
    fetchTotalCustomers();
  }, []);

  const fetchTotalCustomers = async () => {
    try {
      const { count, error } = await supabase
        .from('customers')
        .select('*', { count: 'exact' });

      if (error) {
        throw error;
      }

      setTotalCustomers(count);
    } catch (error) {
      console.error('Error fetching total customers:', error);
    }
  };

  return (
    <div className="dashboard-container p-6 min-h-screen" style={{ marginLeft: '-25px' }}>
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
        <div className="bg-white p-6 rounded-lg shadow-md border-2 border-gray-400">
          <h2 className="text-sm font-medium text-gray-500">Total Customers</h2>
          <p className="text-2xl font-bold">{totalCustomers}</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md border-2 border-gray-400">
          <h2 className="text-sm font-medium text-gray-500">Total Products</h2>
          <p className="text-2xl font-bold">0</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md border-2 border-gray-400">
          <h2 className="text-sm font-medium text-gray-500">Financials</h2>
          <p className="text-2xl font-bold">0</p>
        </div>
      </div>
      <div className="mt-6">
        <div className="bg-white p-6 rounded-lg shadow-md border-2 border-gray-400">
          <h2 className="text-sm font-medium text-gray-500">Top 7 Cities with Most Customers</h2>
          <BarChart />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
