// src/components/Sidebar.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import Pur2oimage from './images/pur2o.png';
import { HomeIcon, UsersIcon, FolderIcon, CalendarIcon, DocumentIcon, ChartPieIcon, Cog6ToothIcon } from '@heroicons/react/24/outline';

function Sidebar({ userRole }) {  // Accept userRole as a prop
  return (
    <div className="h-screen w-64 bg-gray-900 text-gray-300 flex flex-col">
      <div className="flex items-center justify-center h-16 bg-gray-800">
        <img src={Pur2oimage} alt="Logo" className="h-8 w-auto" />
      </div>
      <nav className="flex-1 px-2 py-4 space-y-1">
        <NavLink to="/dashboard" className={({ isActive }) => `group flex items-center px-2 py-2 text-sm font-medium rounded-md ${isActive ? 'bg-gray-700 text-white' : 'hover:bg-gray-700 hover:text-white'}`}>
          <HomeIcon className="mr-3 h-5 w-5" />
          Dashboard
        </NavLink>
        <NavLink to="/customers" className={({ isActive }) => `group flex items-center px-2 py-2 text-sm font-medium rounded-md ${isActive ? 'bg-gray-700 text-white' : 'hover:bg-gray-700 hover:text-white'}`}>
          <UsersIcon className="mr-3 h-5 w-5" />
          Customers
        </NavLink>
        {userRole === 'manager' && (  // Conditional rendering for managers
          <>
            <NavLink to="/staff" className={({ isActive }) => `group flex items-center px-2 py-2 text-sm font-medium rounded-md ${isActive ? 'bg-gray-700 text-white' : 'hover:bg-gray-700 hover:text-white'}`}>
              <UsersIcon className="mr-3 h-5 w-5" />
              Staff
            </NavLink>
            <NavLink to="/financials" className={({ isActive }) => `group flex items-center px-2 py-2 text-sm font-medium rounded-md ${isActive ? 'bg-gray-700 text-white' : 'hover:bg-gray-700 hover:text-white'}`}>
              <ChartPieIcon className="mr-3 h-5 w-5" />
              Financials
            </NavLink>
          </>
        )}
        <NavLink to="/calendar" className={({ isActive }) => `group flex items-center px-2 py-2 text-sm font-medium rounded-md ${isActive ? 'bg-gray-700 text-white' : 'hover:bg-gray-700 hover:text-white'}`}>
          <CalendarIcon className="mr-3 h-5 w-5" />
          Calendar
        </NavLink>
        <NavLink to="/inventory" className={({ isActive }) => `group flex items-center px-2 py-2 text-sm font-medium rounded-md ${isActive ? 'bg-gray-700 text-white' : 'hover:bg-gray-700 hover:text-white'}`}>
          <DocumentIcon className="mr-3 h-5 w-5" />
          Inventory
        </NavLink>
      </nav>
      <div className="px-2 py-4">
        <button className="w-full flex items-center justify-center px-2 py-2 text-sm font-medium rounded-md text-gray-400 hover:text-white hover:bg-gray-700">
          <Cog6ToothIcon className="mr-3 h-5 w-5" />
          Settings
        </button>
      </div>
    </div>
  );
}

export default Sidebar;
